import React from "react";

// Images Wells
import Events_First from "../images/Events_First.jpg";
import Events_Second from "../images/Events_Second.jpg";
import Events_Third from "../images/Events_Third.jpg";

const Events = () => {
  return (
    <div id="Homepage__Services" className="Homepage__Services">
      <div className="container py-10 xl:py-20 px-4 md:px-0">
        <div className="mb-6 text-center md:mb-16">
          <h2 className="mb-6  max-w-[1100px] mx-auto">
            Get Ready For Our Next Live Broadcast From a Christian Music Event!
          </h2>
          <p className="lead max-w-[1150px] m-auto">
            We would like to broadcast live from Christian music festivals and
            worship conferences, to share the atmosphere and buzz with you, to
            take you there virtually, through us. Joining together in praise and
            worship, live. But don't worry if you cannot participate in the live
            broadcast, you can enjoy a shortened recording of it. You can listen
            to them here anytime.
          </p>
          <h3 className="mt-10">The Plans for 2024:</h3>
        </div>
        <div className="text-center md:flex">
          <div className="md:flex-1">
            <img
              src={Events_First}
              alt="Under planning for 2024"
              title="Under planning for 2024"
            />
            <p className="mt-6 mb-2">
              <strong className="">Under Planning</strong>
            </p>
          </div>
          <div className="my-8 md:my-0 md:flex-1 md:mx-16">
            <img
              src={Events_Second}
              title="Under planning for 2024"
              alt="Under planning for 2024"
            />
            <p className="mt-6  mb-2">
              <strong className="">Under Planning</strong>
            </p>
          </div>
          <div className="md:flex-1">
            <img
              src={Events_Third}
              alt="Under planning for 2024"
              title="Under planning for 2024"
            />
            <p className="mt-6  mb-2">
              <strong className="">Under Planning</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

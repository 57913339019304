import React from "react";

import Peter from "../images/peter.jpg";
import Ildiko from "../images/ildiko.jpg";
import Citifaith from "../images/Citifaith.png";

const About = () => {
  return (
    <div>
      {/* Who We Are */}
      <div
        id="Homepage__ThirdSection"
        className="Homepage__ThirdSection px-4 py-16 xl:py-20"
      >
        <div className="container">
          {/* First */}
          <h2 className="mb-10 md:mb-10 text-center">About Us</h2>
          <div className="xl:flex">
            <div className="md:flex-1 self-start text-center md:flex md:justify-center md:flex-col">
              <p className="text-black lead max-w-[800px] mb-8 md:mb-8 mx-auto">
                We are an independent, UK based licensed radio. All of our team
                members are newborn Christians. We do not serve you by teachings
                and preachings but by worship. The music is from many genres,
                created and played by Christian artists.
              </p>
              <p className="text-black lead max-w-[800px] mb-8 md:mb-8 mx-auto">
                Our main goal is to support the Lord's Church and show everybody
                how merciful and good our God is, and reach out and touch as
                many lives as possible for an excellent chance to make a
                difference in our world.
              </p>
              <p className="lead max-w-[800px] mb-8 md:mb-8 mx-auto">
                This Christian Music Radio was established in 2023 by Peter
                Kremniczki. He spent years as a live broadcaster at a radio
                station in Pecs, Hungary. He was also a DJ for over 20 years,
                working at parties and clubs in the night, serving the world's
                people. Then, in 2018, the significant change came. He met God,
                the Lord Jesus Christ. The drastic and deeply touching encounter
                made him finish his career as a DJ and follow Jesus with all his
                heart.
              </p>
              <p className="lead max-w-[800px] mb-8 md:mb-8 mx-auto">
                Years went by, and in 2023, an idea came to him that inspired
                him to start a new 'hobby'. To continue his musical journey, but
                from now on, on the other side. To become a faithful DJ for
                God's people. His experience prepared him to get ready for this
                new task. With a renewed life in Christ empowered by God's Word
                and the Holy Spirit, he aims to encourage everybody by the Word
                of God, by testimonies about the work of the Lord today, and by
                praise and worship.
              </p>
              <p className="lead max-w-[800px] mb-8 md:mb-8 mx-auto">
                We run the Radio only by donations, so please feel free to
                support our vision to find a way to more and more people's
                hearts and souls, helping them in their daily tasks through
                music, sharing encouragement, hope, and love from God through
                worship and praise in waves, in 24/7.
              </p>
            </div>
          </div>

          {/* Team members */}
          {/* <div className="text-center md:flex">
            <div className="md:flex-1">
              <img
                src={Peter}
                alt="Peter Kremniczki - CEO"
                title="Peter Kremniczki - CEO"
                style={{ display: "inline-flex" }}
              />
              <h4 className="mt-6 mb-2 text-warning font-bold">
                Peter Kremniczki
              </h4>
              <p className="lead font-bold uppercase italic">
                Music editor, Operations director, Presenter, CEO
              </p>
              <p className="mt-2">
                He is tasked with selecting and curating playlists, managing the
                on-air music rotation, and staying attuned to audience
                preferences to ensure a dynamic and engaging musical experience.
                He oversees the overall functioning of the station, managing
                technical aspects, coordinating staff, and ensuring smooth
                day-to-day operations to meet broadcasting goals and standards.
                He represents the radio in building connections with churches,
                musicians, and event organisers.
              </p>
            </div>
            <div className="mt-8 md:mt-0 md:flex-1 md:ml-32">
              <img
                src={Ildiko}
                alt="Ildiko Hankoszky - Co-Founder"
                title="Ildiko Hankoszky - Co-Founder"
                style={{ display: "inline-flex" }}
              />
              <h4 className="mt-6 mb-2 text-warning font-bold">
                Ildiko Hankoszky
              </h4>
              <p className="lead font-bold uppercase italic">Co-founder</p>
              <p className="mt-2">
                She is involved in the establishment and development of the
                station, contributing to the overall vision, strategic planning,
                and fundraising efforts. She collaborates with other co-founders
                to shape the station&#39;s identity, programming content, and
                business strategy, playing a pivotal role in its inception and
                growth.
              </p>
            </div>
          </div> */}

          {/* Supporters */}
          <h2 className="mt-2 text-center">Supporters:</h2>
          <a
            href="https://www.citifaith.co.uk/"
            title="Citifaith Church Official Website"
            target="_blank"
          >
            <img
              src={Citifaith}
              alt="Citifaith Church"
              title="Citifaith Church"
              className="max-w-[450px] mx-auto mt-10"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Banners
import AppleStore_Banner from "../images/AppleStore_Banner.png";
import GooglePlay_Banner from "../images/GooglePlay_Banner.png";
import Instagram_Icon from "../images/Instagram_Icon.png";
import Facebook_Icon from "../images/Facebook_Icon.png";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container text-center py-6">
        <div className="flex items-center flex-col lg:flex-row">
          {/* Banners */}
          <div className="flex-1">
            <a
              href="https://apps.apple.com/gb/app/praisewaveradio/id6476927866"
              title="Install our app on the Apple Store"
              target="_blank"
            >
              <img
                src={AppleStore_Banner}
                alt="Apple Store"
                title="Apple Store"
                className="max-w-[150px]  lg:mx-auto mb-4"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.app.praisewaveradio"
              title="Install our app on Google Play"
              target="_blank"
            >
              <img
                src={GooglePlay_Banner}
                alt="Google Play"
                title="Google Play"
                className="max-w-[150px] lg:mx-auto "
              />
            </a>
          </div>
          {/* Copyright */}
          <div className="flex-3 my-10 max-w-[500px] lg:max-w-[800px]">
            <p className="lead tracking-[2px] mb-2 font-bold">
              Praise Wave Radio - PPL and PRS License Radio
            </p>
            <p className="mt-0 lead tracking-[2px] font-bold">
              2024 © All Rights Reserved
            </p>
          </div>
          {/* Instagram and Facebook */}
          <div className="flex-1">
            <a
              target="_blank"
              href="https://www.instagram.com/praisewaveradio/"
              title="Follow us on Instagram"
            >
              <img
                src={Instagram_Icon}
                alt="Follow us on Instagram"
                title="Follow us on Instagram"
                className="max-w-[80px] lg:mx-auto"
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61556392567441"
              title="Like us on Facebook"
            >
              <img
                src={Facebook_Icon}
                alt="Like us on Facebook"
                title="Like us on Facebook"
                className="max-w-[80px] lg:mx-auto"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

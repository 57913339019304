import React from "react";

const Contact = () => {
  return (
    <div
      id="Homepage__Testimonials"
      className="Homepage__ThirdSection px-4 py-16 xl:py-20"
    >
      <div className="container">
        {/*  */}
        <h2 className="mb-10 text-center">Contact Us</h2>
        <p className="lead max-w-[650px] m-auto text-center mb-6">
          Join us as we share the uplifting melodies of faith and joy, and
          experience the transformative power of Praise Wave Radio. Feel free to
          reach out to us with any questions or comments.
        </p>
        <p className="lead max-w-[650px] m-auto text-center mb-6">
          If you would like to get in touch with us, please send an email to:{" "}
          <a
            className="text-info"
            title="Send us an email and get in contact with us"
            href="mailto:info@praisewaveradio.co.uk"
          >
            info@praisewaveradio.co.uk
          </a>
        </p>

        <p className="lead max-w-[650px] m-auto text-center mb-6">
          Or on Instagram:{" "}
          <a
            className="text-info"
            target="_blank"
            href="https://www.instagram.com/praisewaveradio/"
            title="Follow us on Instagram"
          >
            @praisewaveradio
          </a>
        </p>

        <p className="lead max-w-[650px] m-auto text-center mb-6">
          Or by phone:{" "}
          <a className="text-info" href="tel:+447920642324">
            07920 642324
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;

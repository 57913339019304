import React from "react";

const Radio = () => {
  return (
    <div>
      {/* Radio */}
      <div
        id="Homepage__FourthSection"
        className="Homepage__FourthSection text-center my-10 xl:my-20"
      >
        <div className="container">
          <div>
            {" "}
            <iframe
              width="100%"
              height="330"
              className="max-w-[300px] mx-auto md:max-w-[700px] lg:max-w-[800px] xl:max-w-[980px] xl:h-[500px]"
              src="https://hello.citrus3.com:2020/AudioPlayer/praisewaveradio?mount=&"
              border="0"
            ></iframe>
          </div>
        </div>
        {/* Recently played */}
        <div
          data-widget="mcp-recently-played"
          data-name="praisewaveradio"
          data-limit="5"
          data-covers="true"
        />
      </div>
    </div>
  );
};

export default Radio;

import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// Images Sliders
import Homepage_Girl from "../images/Homepage_Girl.jpg";

// Carousel
import Flickity from "flickity";
import "flickity/css/flickity.css";

// Images
import Upcoming_Event from "../images/Upcoming_Event.jpg";
import Testimony from "../images/Testimony.jpeg";
import Newest_Song from "../images/Newest Song.jpg";

const Home = () => {
  // Variables
  const flickityRef = useRef(null);
  const navigate = useNavigate();

  // Functions

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after navigating
  };

  useEffect(() => {
    const flickity = new Flickity(flickityRef.current, {
      prevNextButtons: false,
    });

    if (flickityRef.current) {
      const flickityPageDots = flickityRef.current.querySelector(
        ".flickity-page-dots"
      );
      flickityPageDots.classList.add("container");
    }

    return () => {
      flickity.destroy();
    };
  }, []);

  return (
    <section className="Homepage">
      {/* Hero Section */}
      <div className="Homepage__Carousel">
        <div ref={flickityRef} className="main-carousel">
          <div className="carousel-cell">
            <div className="container">
              <div className="flex justify-center">
                <div className="md:flex md:flex-col md:justify-center text-center">
                  <h1 className="mb-4">Christian Radio Station</h1>
                  <h2 className="mb-8 font-bold">
                    <span className="">PraiseWave Radio</span>
                  </h2>
                  <p className="mb-4 lead max-w-[700px]">
                    Where faith and music come together to inspire and uplift
                    your spirit! Tune in to experience the power of Christian
                    music that speaks to the heart and strengthens the soul.
                  </p>
                  <p className="mb-4 lead max-w-[700px]">
                    There is no preaching or teaching here, but whether you’re
                    seeking encouragement, solace, or simply just music, Praise
                    Wave is here to accompany you.
                  </p>
                </div>
              </div>
            </div>
            <img
              src={Homepage_Girl}
              className="mt-[20px] md:mt-0 md:"
              alt="Man playing guitar in a concert"
              title="Man playing guitar in a concert"
            />
          </div>
        </div>
      </div>

      {/* Listener Feedback */}
      <div className="md:flex md:flex-col md:justify-center text-center">
        <h4 className="text-center my-8">They said:</h4>
        <div className="text-center md:flex mx-8">
          <div className="md:flex-1">
            <p className="mt-2">
              I was preparing for church and was preaching on prayer when I went
              to Praise Wave and on it was playing{" "}
              <strong>"These Days" by Jeremy Camp and the Word</strong>.
              <br />
              <br />
              These days are fast and furious and feels like the worst is ahead
              of us. Sometimes it’s hard to feel at home. I believe that you and
              I are in the right place at the right time. God called us by name.
              AND HE DOESN’T MAKE MISTAKES. WE WERE MADE FOR THESE DAYS.
              <br />
              <br />
              It just spoke to me as I was preaching on prayer that we need to
              believe that we are in the right place at the right time. And this
              scripture came to mind.
              <br />
              <br />
              ”Nevertheless, do not let this one fact escape you, beloved, that
              with the Lord one day is as a thousand years and a thousand years
              as one day. [Ps. 90:4.] The Lord does not delay and is not tardy
              or slow about what He promises, according to some people’s
              conception of slowness, but He is long- suffering (extraordinarily
              patient) toward you, not desiring that any should perish, but that
              all should turn to repentance.“
              <br />
              <br />
              God has His perfect timing for our lives and also the world. Not
              to let our prayer life be filled with fear and doubt but just keep
              consistent and persistent in our prayers.
            </p>
            <p className="lead font-bold uppercase italic mt-3">
              Pastor Les Everson
            </p>
          </div>
          <div className="mt-8 md:mt-0 md:flex-1 md:ml-32">
            <p className="mt-2">
              From the middle of 2019, I’d struggled to find my purpose and a
              job where I was secure. Many things came that only made this more
              difficult, and at times left me feeling broken and desperate
              (COVID, a car accident, subsequent payments/debts that sought to
              overwhelm, and unfulfilling environments).
              <br />
              <br />
              But God was the one constant I could cry out to, and so I did. And
              time and time again, JUST as I needed Him, He came through. Now I
              am in a job where I can truly say I find fulfillment (even with
              the myriad of layoffs, my bosses and colleagues continue to praise
              me for my work), I am married, own a stable and new vehicle, and
              have been able to step into my purpose for this current season at
              my church.
              <br />
              <br />
              All in all, all I can say, is that God IS, HAS BEEN, and ALWAYS
              WILL BE truly good.
              <br />
              <br />
              By the way, the song that got me through unemployment and being
              stranded in debt was <strong>"O.K." by Kirk Franklin</strong>.
              <br />
              <br />
              Give it a listen, if you too were or are ever stuck in a place
              like I was. Things WILL be okay.
            </p>
            <p className="lead font-bold uppercase italic mt-3">
              Ruben, Swindon
            </p>
          </div>
        </div>
      </div>

      {/* Grid Top Row */}
      <div className="text-center md:flex flex-row px-4 mt-2 mb-6 xl:py-20">
        {/* Grid Item 1 */}
        <div className="md:flex-1">
          <h4 className="mt-6 mb-4 text-warning font-bold">
            <Link
              to="/events"
              onClick={() => handleClick("/events")}
              title="Check the new upcoming events!"
              className="underline text-info"
            >
              <strong>
                Upcoming <br />
                Event
              </strong>
            </Link>
          </h4>
          <img
            src={Upcoming_Event}
            title="Christian concert with a deep power of the holy spirit"
            alt="Christian concert with a deep power of the holy spirit"
            style={{ display: "inline-flex" }}
          />
          <p className="mt-2">
            Join us for a transcendent experience on our Christian radio
            station! We take you there through us, fostering a sense of
            community and joy.
          </p>
        </div>
        {/* Grid Item 2 */}
        <div className="mt-8 md:mt-0 md:flex-1 md:ml-32">
          <h4 className="mt-6 mb-4 text-warning font-bold">
            <Link
              to="/testimonies"
              onClick={() => handleClick("/testimonies")}
              title="Check the newest testimonies!"
              className="underline text-info"
            >
              <strong>
                Most Recent <br />
                Testimony
              </strong>
            </Link>
          </h4>
          <img
            src={Testimony}
            title="Christians gathering and cooking marshmallows"
            alt="Christians gathering and cooking marshmallows"
            style={{ display: "inline-flex" }}
          />
          <p className="mt-2">
            True, profound, and encouraging testimonies about God's touches from
            the MUSICIANS whose songs we share.
          </p>
        </div>
      </div>

      {/* Grid Bottom Row */}
      <div className="text-center md:flex flex-row px-4 mt-2 mb-10 xl:py-20">
        {/* Grid Item 3 */}
        <div className="md:flex-1">
          <h4 className="mt-6 mb-4 font-bold">
            <strong>
              Newest Song <br />
              On The Radio
            </strong>
          </h4>
          <img
            src={Newest_Song}
            title="Worship Through It"
            alt="Worship Through It"
            style={{ display: "inline-flex" }}
          />
          <p className="mt-2">
            My new single "Worship Through It" ft. Chris Brown is OUT TODAY!
            <br />
            It has been so, so amazing watching this project come to life, we
            hope it blesses you!
            <br />
            Steam now wherever you listen to music.
          </p>
        </div>
        {/* Grid Item 4 */}
        <div className="mt-8 md:mt-0 md:flex-1 md:ml-32">
          <h4 className="mt-6 mb-4 font-bold">
            <strong>
              Artist Of <br />
              The Week
            </strong>
          </h4>
          {/* <img
            src={Testimony}
            title="Christians gathering and cooking marshmallows"
            alt="Christians gathering and cooking marshmallows"
            style={{ display: "inline-flex" }}
          /> */}
          <p className="mt-2">Coming soon...</p>
        </div>
      </div>

      {/* Bible Verse */}
      <div className="Homepage__Quote text-center pt-10 pb-10 px-4 md:px-0">
        <div className="container">
          <blockquote className="text-white max-w-[900px] mx-auto">
            “Give thanks to the Lord and proclaim his greatness. Let the whole
            world know what he has done.” -{" "}
            <span className="text-accent">1 Chronicles 16:8 - NLT</span>
          </blockquote>
        </div>
      </div>

      {/* Text Below Bible Verse */}
      <div className="text-center pt-10 pb-10 px-4 md:px-0">
        <div className="container">
          <h4 className="max-w-[820px] mx-auto">
            Take Us With You and Listen Anywhere, Anytime!
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Home;

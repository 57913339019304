import React from "react";
import { useState, useEffect } from "react";
import $ from "jquery";

// Images
import Logo_V2 from "../images/Logo_V2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  // Variables
  const [NavActive, setNavActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const ulClassName = NavActive ? "active" : "";

  // Functions
  const clickMenuMobile = () => {
    setNavActive((prevNavActive) => !prevNavActive);
  };

  const handleClick = (path) => {
    // Set NavActive to false when a link is clicked
    setNavActive(false);
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after navigating
  };

  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="px-4 md:px-0">
      <div className="container mx-auto">
        {/* Left Side Header */}
        <article className="header_leftside">
          <Link
            to="/"
            title="Go back to the homepage!"
            onClick={() => handleClick("/home")}
          >
            <img
              src={Logo_V2}
              alt="Praise Wave Radio"
              title="Praise Wave Radio"
            />
          </Link>
        </article>
        {/* Right Side Header */}
        <article className="header_rightside">
          <ul className={ulClassName}>
            <li>
              <Link
                to="/"
                onClick={() => handleClick("/home")}
                className={
                  isLinkActive("/home") ? "text-gold" : "hover:text-yellow-500"
                }
                title="Homepage"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => handleClick("/about")}
                className={
                  isLinkActive("/about") ? "text-gold" : "hover:text-yellow-500"
                }
                title="About us"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/events"
                onClick={() => handleClick("/events")}
                className={
                  isLinkActive("/events")
                    ? "text-gold"
                    : "hover:text-yellow-500"
                }
                title="Check our events!"
              >
                Events
              </Link>
            </li>

            <li>
              <Link
                to="/testimonies"
                onClick={() => handleClick("/testimonies")}
                className={
                  isLinkActive("/testimonies")
                    ? "text-gold"
                    : "hover:text-yellow-500"
                }
                title="Testimonies"
              >
                Testimonies
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => handleClick("/contact")}
                className={
                  isLinkActive("/contact")
                    ? "text-gold"
                    : "hover:text-yellow-500"
                }
                title="Contact Us"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=LLMB9FL228UH8"
                title="Donate us"
                target="_blank"
                className="btn-gold btn-large btn"
              >
                Donations
              </a>
            </li>
            <li>
              <Link
                to="/radio"
                onClick={() => handleClick("/radio")}
                title="Listen to our Radio!"
                className={
                  isLinkActive("/about")
                    ? "text-gold"
                    : "btn-large btn text-gold pr-0 radio"
                }
              >
                LIVE RADIO
              </Link>
            </li>
          </ul>
          <button
            onClick={clickMenuMobile}
            className={NavActive ? "active" : ""}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </article>
      </div>
    </header>
  );
};

export default Header;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCoffee,
  faAddressBook,
  faBars,
  faRadio,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCoffee,
  faAddressBook,
  faBars,
  faRadio
  // Add other icons here
);

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./Tailwind/Tailwind.css";
import "./FontAwesome/FontAwesome.css";
import "./FontAwesome/FontAwesome.js";

// SCSS Files
import "./scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

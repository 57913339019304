import React from "react";

// React Router Dom
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Radio from "./pages/Radio";
import Contact from "./pages/Contact";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Testimonies from "./pages/Testimonies";
import Events from "./pages/Events";

function App() {
  return (
    <Router>
      <Header />

      <section className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/radio" element={<Radio />} />
          <Route path="/testimonies" element={<Testimonies />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </section>

      <Footer />
    </Router>
  );
}

export default App;

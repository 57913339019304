import React from "react";

// Pictures
import Coming_Soon from "../images/Coming_Soon.jpg";

const Testimonies = () => {
  return (
    <div
      id="Homepage__Testimonials"
      className="Homepage__ThirdSection  py-16 xl:py-20"
    >
      <div className="container px-4">
        {/*  */}
        <h2 className="mb-10 text-center">Testimonies</h2>
        <p className="lead max-w-[1000px] m-auto text-center mb-6">
          True, profound, and encouraging testimonies from the musicians whose
          songs we share. Heartfelt stories that echo the transformative power
          of God, and how He changed lives and healed the broken. We believe
          that Acts in the Bible is not finished yet. He is helping, supporting,
          leading, talking, and feeding us every day. But above all else, He is
          loving us. Stay tuned for upcoming testimonials!
        </p>
        {/*         
        <p className="lead max-w-[650px] m-auto text-center mb-6">
          If you would like to share your testimony with the world, you can do
          it by sending it to us in an email as a writing or a short recording.
          Of course, we have to validate it and with the help of the Holy
          Spirit, we will do it.{" "}
          <a className="text-info" href="mailto:info@praisewaveradio.co.uk">
            info@praisewaveradio.co.uk
          </a>
        </p> */}
      </div>

      {/* Pictures */}
      <div className="container mt-16">
        <div className="flex flex-col xl:flex-row">
          <div className="flex-1">
            <img
              src={Coming_Soon}
              alt="Coming Soon!"
              className="max-w-[300px] mb-16 xxl:mb-0 xl:max-w-[350px] xxl:max-w-[450px] mx-auto"
            />
          </div>

          <div className="flex-1">
            <img
              src={Coming_Soon}
              alt="Coming Soon!"
              className="max-w-[300px] mb-16 xxl:mb-0 xl:max-w-[350px] xxl:max-w-[450px] mx-auto"
            />
          </div>

          <div className="flex-1">
            <img
              src={Coming_Soon}
              alt="Coming Soon!"
              className="max-w-[300px] mb-16 xxl:mb-0 xl:max-w-[350px] xxl:max-w-[450px] mx-auto"
            />
          </div>
        </div>
      </div>

      {/* Quote */}
      <div className="Homepage__Quote text-center pt-10 pb-10 px-4 md:px-0 bg-black mt-16">
        <div className="container">
          <blockquote className="text-white">
            “A truthful witness saves lives...” -{" "}
            <span className="text-accent">Prov 14,25</span>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default Testimonies;
